.svc-tab-translation {
  width: 100%;
  height: 100%;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}
.svc-tab-translation * {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}

.st-properties {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  flex-grow: 1;
  padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.st-properties .spg-table .spg-table__cell:first-of-type {
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.st-properties .spg-table .spg-table__question-wrapper {
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) var(--sjs-base-unit, var(--base-unit, 8px));
}
.st-properties .spg-table .spg-table__question-wrapper .spg-checkbox {
  margin: 0;
}
.st-properties .spg-table .spg-table__question-wrapper .spg-selectbase__label {
  gap: 0;
}
.st-properties .spg-table .spg-checkbox--disabled .spg-checkbox__rectangle,
.st-properties .spg-table .spg-checkbox--disabled .spg-checkbox__rectangle:hover {
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.st-no-strings {
  --center-text-font-size: var(--sjs-base-unit, var(--base-unit, 8px));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 400;
  font-size: calc(2 * var(--center-text-font-size));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
  overflow: hidden;
}

.st-content {
  display: flex;
  height: 100%;
}

.st-strings {
  height: calc(100% - 6 * var(--sjs-base-unit, var(--base-unit, 8px)));
  overflow-y: auto;
  overflow-x: hidden;
}

.st-strings-wrapper {
  flex-grow: 1;
}

.st-property-panel {
  flex-shrink: 0;
  flex-grow: 0;
  width: 450px;
  border-left: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
}

.st-property-panel .spg-panel__content .spg-row {
  margin-top: 0;
}

.st-property-panel .spg-panel__content .spg-row:first-child {
  margin-top: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.st-strings-header .st-table__cell {
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  height: auto;
}

.st-root-modern {
  width: 100%;
}

.st-title.st-panel__title,
.st-table__cell.st-table__cell--header {
  --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.75 * var(--small-bold-font-size));
  line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  text-align: left;
}

.st-title.st-panel__title {
  height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin: 0;
  background-color: var(--sjs-general-backcolor-dim-light, var(--background-dim-light, #f9f9f9));
  border-bottom: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
}

.st-table tr {
  display: flex;
  align-items: stretch;
}

.st-table__cell {
  display: block;
  flex: 1 1;
  box-sizing: border-box;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  border-bottom: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) 0;
}

td.st-table__cell:first-of-type {
  --default-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  line-height: calc(1.5 * var(--default-bold-font-size));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  max-width: 300px;
  padding-right: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
td.st-table__cell:first-of-type span {
  display: inline-block;
  padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.st-panel-indent .st-table__cell:first-of-type span {
  padding-left: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.st-table {
  width: 100%;
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;
}

.st-comment {
  --default-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  line-height: calc(1.5 * var(--default-font-size));
  display: block;
  width: calc(100% - 3 * var(--sjs-base-unit, var(--base-unit, 8px)));
  border: unset;
  outline: none;
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  resize: none;
  padding: 0;
}

.sd-translation-line-skeleton {
  min-height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
}

.st-navigation-btn {
  padding-left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  white-space: nowrap;
}
.st-navigation-btn .sd-action__icon {
  margin-left: 0;
}

.st-navigation-btn.sd-action--icon {
  padding: var(--sjs-base-unit, var(--base-unit, 8px));
}
.st-navigation-btn.sd-action--icon:not(.sd-action--pressed):hover, .st-navigation-btn.sd-action--icon:not(.sd-action--pressed):focus {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}
.st-navigation-btn.sd-action--icon svg use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-translation-machine {
  margin-right: auto;
}

.st-translation-machine-from {
  margin-right: auto;
}

.st-translation-machine-from__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  white-space: nowrap;
}

.st-translation-machine-from__container--disabled {
  gap: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.st-translation-machine-from__btn {
  flex-direction: row-reverse;
  gap: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  white-space: nowrap;
}
.st-translation-machine-from__btn .sd-action__icon {
  width: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: 0;
}
.st-translation-machine-from__btn.sd-action--pressed:not(.sd-action--active) {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
}
.st-translation-machine-from__btn:disabled {
  padding-left: 0;
  padding-right: 0;
  opacity: 1;
}

.st-translation-machine-from__btn:disabled .sd-action__title,
.st-translation-machine-from__title {
  color: var(--sjs-general-dim-forecolor-light, rgba(0, 0, 0, 0.45));
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  line-height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-weight: 400;
}

.st-body__footer {
  padding: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor, var(--background, #fff));
  border-bottom: 1px solid var(--sjs-border-light, var(--border-light, #eaeaea));
}

.st-translation-dialog .sv-popup__body-header {
  margin-bottom: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.st-translation-dialog .st-strings-wrapper {
  border-radius: 4px;
  box-shadow: var(--sjs-shadow-small, 0px 1px 2px 0px rgba(0, 0, 0, 0.15));
  overflow: hidden;
}

.st-container-modern .sv-components-column--expandable {
  width: auto;
}

.st-body .sd-row--fade-out,
.st-body .sd-row--fade-in {
  animation: none;
}
.st-body .sd-element-wrapper--fade-out,
.st-body .sd-element-wrapper--fade-in {
  animation: none;
}
.st-body .sd-element__content--fade-in,
.st-body .sd-element__content--fade-out {
  animation: none;
}
.st-body .sd-paneldynamic__panel-wrapper--fade-in,
.st-body .sd-paneldynamic__panel-wrapper--fade-out,
.st-body .sd-paneldynamic__panel-wrapper--fade-out-left,
.st-body .sd-paneldynamic__panel-wrapper--fade-out-right,
.st-body .sd-paneldynamic__panel-wrapper--fade-in-left,
.st-body .sd-paneldynamic__panel-wrapper--fade-in-right {
  animation: none;
}
.st-body .sd-table__row--fade-out,
.st-body .sd-table__row--fade-in {
  animation: none;
}