svc-toolbox {
  display: flex;
  flex-direction: column;
  position: absolute;
}

.svc-toolbox {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.svc-toolbox .spg-search-editor_container {
  padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-end: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-start: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  width: unset;
  flex-grow: 1;
}
.svc-toolbox .spg-search-editor_input {
  width: 0;
  flex-grow: 1;
}
.svc-toolbox .spg-search-editor_toolbar-counter {
  display: none;
}
.svc-toolbox .spg-search-editor_bar-item.sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled {
  background-color: var(--sjs-special-red-light, var(--red-light, rgba(230, 10, 62, 0.1)));
  border-radius: 100px;
}
.svc-toolbox .spg-search-editor_bar-item.sv-action-bar-item:not(.sv-action-bar-item--pressed):hover:enabled svg use {
  fill: var(--sjs-special-red, var(--red, #e60a3e));
}

.svc-toolbox__scroller {
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
  overflow: hidden;
  direction: rtl;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.svc-side-bar .svc-toolbox {
  direction: ltr;
}

.svc-toolbox--compact {
  width: 100vw;
  margin-right: calc(-100vw + 10.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-toolbox--compact .spg-search-editor_container {
  opacity: 0;
  position: absolute;
}
.svc-toolbox--compact .svc-toolbox__category-separator--search {
  margin-bottom: 0;
  margin-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-height: 1px;
}

.svc-toolbox__tool.svc-toolbox__search-button {
  z-index: 20;
  justify-content: center;
}
.svc-toolbox__tool.svc-toolbox__search-button .sv-dots__item {
  margin-top: 0;
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox--flyout {
  width: 100vw;
  margin-right: calc(-100vw + 10.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  overflow: visible;
}
.svc-toolbox--flyout .spg-search-editor_container {
  opacity: 1;
  position: relative;
}
.svc-toolbox--flyout .svc-toolbox__panel {
  z-index: 200;
  position: relative;
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  width: fit-content;
  box-shadow: 1px 0 0 0 var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16))), var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
}

.svc-toolbox--scrollable .svc-toolbox__scroller {
  overflow-y: auto;
}

.svc-toolbox--scrollable.svc-toolbox--scroll-locked .svc-toolbox__scroller {
  overflow: scroll;
}

.svc-toolbox--searchable .svc-toolbox__scroller {
  padding-top: 0;
}
.svc-toolbox--searchable .svc-toolbox__search-container {
  display: flex;
}
.svc-toolbox--searchable:not(.svc-toolbox--scrollable) .svc-toolbox__scroller {
  padding-top: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-toolbox--searchable:not(.svc-toolbox--scrollable) .svc-toolbox__scroller .svc-toolbox__search-container {
  top: calc(-9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-top: calc(-9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox__panel {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.svc-creator__toolbox--right:not(.svc-creator__toolbox--right) .svc-toolbox__search-container,
[dir=rtl]:not(.svc-creator__toolbox--right) .svc-toolbox__search-container,
[style*="direction:rtl"]:not(.svc-creator__toolbox--right) .svc-toolbox__search-container,
[style*="direction: rtl"]:not(.svc-creator__toolbox--right) .svc-toolbox__search-container {
  direction: rtl;
}
.svc-creator__toolbox--right .svc-toolbox__scroller,
[dir=rtl] .svc-toolbox__scroller,
[style*="direction:rtl"] .svc-toolbox__scroller,
[style*="direction: rtl"] .svc-toolbox__scroller {
  direction: ltr;
}
.svc-creator__toolbox--right .svc-toolbox__panel,
[dir=rtl] .svc-toolbox__panel,
[style*="direction:rtl"] .svc-toolbox__panel,
[style*="direction: rtl"] .svc-toolbox__panel {
  direction: rtl;
}
.svc-creator__toolbox--right .svc-toolbox--compact,
[dir=rtl] .svc-toolbox--compact,
[style*="direction:rtl"] .svc-toolbox--compact,
[style*="direction: rtl"] .svc-toolbox--compact {
  margin-right: 0;
  margin-left: calc(-100vw + 10.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  direction: ltr;
}
.svc-creator__toolbox--right .svc-toolbox--flyout,
[dir=rtl] .svc-toolbox--flyout,
[style*="direction:rtl"] .svc-toolbox--flyout,
[style*="direction: rtl"] .svc-toolbox--flyout {
  margin-right: 0;
  margin-left: calc(-100vw + 10.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  direction: ltr;
  align-items: flex-end;
}
.svc-creator__toolbox--right .svc-toolbox--flyout .svc-toolbox__panel,
[dir=rtl] .svc-toolbox--flyout .svc-toolbox__panel,
[style*="direction:rtl"] .svc-toolbox--flyout .svc-toolbox__panel,
[style*="direction: rtl"] .svc-toolbox--flyout .svc-toolbox__panel {
  box-shadow: -1px 0 0 0 var(--sjs-border-inside, var(--border-inside, rgba(0, 0, 0, 0.16))), var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1)), var(--sjs-shadow-large, 0px 8px 16px 0px rgba(0, 0, 0, 0.1));
}
.svc-creator__toolbox--right .svc-toolbox__tool .sv-action__content,
[dir=rtl] .svc-toolbox__tool .sv-action__content,
[style*="direction:rtl"] .svc-toolbox__tool .sv-action__content,
[style*="direction: rtl"] .svc-toolbox__tool .sv-action__content {
  align-items: flex-end;
}

.svc-toolbox__container {
  direction: ltr;
}

.svc-toolbox--compact:not(.svc-toolbox--flyout) .svc-toolbox__search-container {
  max-width: fit-content;
  margin-left: 0;
  margin-right: 0;
  width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox__search-container {
  direction: ltr;
  position: sticky;
  top: 0;
  z-index: 30;
  background-color: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  padding-top: var(--sjs-base-unit, var(--base-unit, 8px));
  margin-bottom: var(--sjs-base-unit, var(--base-unit, 8px));
  margin-right: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  display: none;
  flex-direction: column;
  width: calc(100% + 3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  box-sizing: border-box;
}

.svc-toolbox__category {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.svc-toolbox__tool:not(.sv-dots) {
  display: flex;
  width: auto;
}

.svc-toolbox__tool .sv-dots__item {
  width: calc(6 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox__category-separator {
  height: 1px;
  width: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-border-default, var(--border, #d6d6d6));
  margin: var(--sjs-base-unit, var(--base-unit, 8px)) calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox--filtering .svc-toolbox__category-separator {
  display: none;
}

.svc-toolbox__category-header {
  height: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background: var(--sjs-general-backcolor-dim, var(--background-dim, #f3f3f3));
  border-bottom: 1px solid var(--sjs-border-default, var(--border, #d6d6d6));
  box-sizing: border-box;
  width: 100%;
  min-width: calc(20 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  position: relative;
}

.svc-toolbox__category-header--collapsed {
  cursor: pointer;
}

.svc-toolbox__category-title {
  font-family: var(--sjs-font-family, var(--font-family));
  font-size: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  font-weight: bold;
  line-height: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  vertical-align: middle;
  display: block;
}

.svc-toolbox__category-header__controls {
  position: absolute;
  right: var(--sjs-base-unit, var(--base-unit, 8px));
  top: var(--sjs-base-unit, var(--base-unit, 8px));
  display: none;
}

.svc-toolbox__category-header__button {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.svc-toolbox__category-header:hover .svc-toolbox__category-header__controls {
  display: block;
}

.svc-toolbox__tool {
  cursor: default;
}
.svc-toolbox__tool .sv-action__content {
  display: flex;
  padding-top: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  flex-direction: column;
  align-items: flex-start;
}

.svc-toolbox__category--collapsed .svc-toolbox__tool {
  height: 0;
  visibility: hidden;
}

.svc-toolbox__category--empty {
  height: 0;
  visibility: hidden;
}

.svc-toolbox__placeholder {
  padding-top: calc(12 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: auto;
  margin-right: auto;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
  --default-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-size: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  line-height: calc(1.5 * var(--default-font-size));
  font-size: 12px;
}