.svc-rating-question-controls {
  position: absolute;
}

.svc-question__content--in-popup .svc-rating-question-controls.svc-item-value-controls {
  display: flex;
}

.svc-question__content--selected .svc-rating-question-controls.svc-item-value-controls {
  display: flex;
}

.svc-rating-question-controls.svc-rating-question-controls--labels-top {
  margin-top: calc(5.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question__content .sd-rating {
  margin-inline-start: calc(9 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  max-width: calc(100% - 9 * var(--sjs-base-unit, var(--base-unit, 8px)));
}
.svc-question__content .sd-rating.sd-rating--wrappable fieldset {
  min-width: 0;
}
.svc-question__content .sd-rating .sd-rating__item-text {
  max-width: 100%;
}
.svc-question__content .sd-rating .sd-rating__item-text .sv-string-editor:focus-within {
  white-space: nowrap;
}
.svc-question__content .sd-rating .sd-rating__item-text::after {
  display: none;
}
.svc-question__content .sd-rating .svc-string-editor__content .sv-string-editor {
  text-overflow: ellipsis;
  overflow: hidden;
}
.svc-question__content .sd-rating .svc-string-editor__content .sv-string-editor:focus-within {
  text-overflow: initial;
}
.svc-question__content .sd-rating.sd-rating--labels-top fieldset .sd-rating__min-text {
  top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-rating.sd-rating--labels-top fieldset .sd-rating__max-text {
  top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-rating.sd-rating--labels-bottom fieldset {
  padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content .sd-rating.sd-rating--labels-bottom fieldset .sd-rating__min-text {
  bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-rating.sd-rating--labels-bottom fieldset .sd-rating__max-text {
  bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-rating.sd-rating--labels-diagonal fieldset {
  padding-bottom: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-question__content .sd-rating.sd-rating--labels-diagonal fieldset .sd-rating__min-text {
  top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-rating.sd-rating--labels-diagonal fieldset .sd-rating__max-text {
  bottom: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-question__content .sd-question--table .sd-rating {
  margin-inline-start: 0;
  width: fit-content;
  margin: auto;
  max-width: fit-content;
}
.svc-question__content .svc-rating-question-controls {
  display: flex;
  width: calc(8 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  min-width: auto;
  justify-content: flex-start;
}
.svc-question__content .sd-rating__item--fixed-size:focus-within {
  width: unset;
  padding: calc(0.5 * (var(--sjs-base-unit, var(--base-unit, 8px)))) calc(2.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-question__content .svc-rating-question-content .sd-question__content {
  overflow-x: visible;
}

.svc-creator--mobile .svc-rating-question-content {
  position: relative;
}
.svc-creator--mobile .svc-rating-question-controls {
  position: absolute;
  top: 0;
}
.svc-creator--mobile .svc-question__content .sd-rating {
  margin-inline-start: 0;
  width: 100%;
  max-width: 100%;
}
.svc-creator--mobile .svc-question__content legend + .sd-rating__item,
.svc-creator--mobile .svc-question__content legend + sv-ng-rating-item + .sd-rating__item,
.svc-creator--mobile .svc-question__content legend + .sd-rating__item-smiley,
.svc-creator--mobile .svc-question__content legend + sv-ng-rating-item-smiley + .sd-rating__item-smiley {
  margin-inline-start: calc(9 * var(--sjs-base-unit, var(--base-unit, 8px)) + 2px);
}
.svc-creator--mobile .svc-question__content legend + .sd-rating__item-text,
.svc-creator--mobile .svc-question__content legend + .sd-rating__item-star,
.svc-creator--mobile .svc-question__content legend + sv-ng-rating-item-star + .sd-rating__item-star {
  margin-inline-start: calc(9 * var(--sjs-base-unit, var(--base-unit, 8px)));
}