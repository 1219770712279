.svc-toolbox__item {
  display: flex;
  position: relative;
  align-items: center;
  z-index: 20;
  outline: none;
  padding: var(--sjs-base-unit, var(--base-unit, 8px)) calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-toolbox__item sv-svg-icon,
.svc-toolbox__item .sv-svg-icon {
  display: block;
  height: calc(3 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox__item-container {
  outline: none;
}
.svc-toolbox__item-container .sv-svg-icon use {
  fill: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.svc-toolbox__item-banner {
  opacity: 0;
  display: flex;
  box-sizing: border-box;
  align-items: center;
  margin-right: 0;
  margin-left: calc(-1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-toolbox__item-banner .svc-toolbox__item-icon {
  position: absolute;
  display: block;
  left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  top: var(--sjs-base-unit, var(--base-unit, 8px));
}
.svc-toolbox__item-banner .svc-toolbox__item-title {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
}
.svc-toolbox__item-banner .sv-svg-icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-creator__toolbox--right .svc-toolbox__item:hover .svc-toolbox__item-banner,
.svc-creator__toolbox--right .svc-toolbox__item:focus .svc-toolbox__item-banner,
[dir=rtl] .svc-toolbox__item:hover .svc-toolbox__item-banner,
[dir=rtl] .svc-toolbox__item:focus .svc-toolbox__item-banner,
[style*="direction:rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner,
[style*="direction:rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner,
[style*="direction: rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner,
[style*="direction: rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner {
  flex-direction: row-reverse;
  padding-right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  margin-left: 0;
  margin-right: 0;
  transform: translateX(calc(1.5 * var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-creator__toolbox--right .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
.svc-creator__toolbox--right .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title,
[dir=rtl] .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
[dir=rtl] .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title,
[style*="direction:rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
[style*="direction:rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title,
[style*="direction: rtl"] .svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
[style*="direction: rtl"] .svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title {
  padding-left: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-right: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-creator__toolbox--right .svc-toolbox__item,
[dir=rtl] .svc-toolbox__item,
[style*="direction:rtl"] .svc-toolbox__item,
[style*="direction: rtl"] .svc-toolbox__item {
  flex-direction: row-reverse;
}
.svc-creator__toolbox--right .svc-toolbox__tool.sv-dots,
[dir=rtl] .svc-toolbox__tool.sv-dots,
[style*="direction:rtl"] .svc-toolbox__tool.sv-dots,
[style*="direction: rtl"] .svc-toolbox__tool.sv-dots {
  place-self: flex-end;
}
.svc-creator__toolbox--right .svc-toolbox__category,
[dir=rtl] .svc-toolbox__category,
[style*="direction:rtl"] .svc-toolbox__category,
[style*="direction: rtl"] .svc-toolbox__category {
  align-items: flex-end;
}
.svc-creator__toolbox--right .svc-toolbox__item-banner .svc-toolbox__item-icon,
[dir=rtl] .svc-toolbox__item-banner .svc-toolbox__item-icon,
[style*="direction:rtl"] .svc-toolbox__item-banner .svc-toolbox__item-icon,
[style*="direction: rtl"] .svc-toolbox__item-banner .svc-toolbox__item-icon {
  left: auto;
  right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox__item-title {
  --small-bold-font-size: var(--sjs-font-size, calc(2 * var(--sjs-base-unit, var(--base-unit, 8px))));
  font-family: var(--sjs-font-family, var(--font-family));
  font-style: normal;
  font-weight: 600;
  font-size: calc(0.75 * var(--small-bold-font-size));
  line-height: var(--sjs-font-size, calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px)))));
  display: block;
  padding-left: var(--sjs-base-unit, var(--base-unit, 8px));
  padding-right: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  white-space: nowrap;
  color: var(--sjs-general-forecolor-light, var(--foreground-light, #909090));
}

.svc-toolbox__item:hover .svc-toolbox__item-banner,
.svc-toolbox__item:focus .svc-toolbox__item-banner {
  max-width: calc(100 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-left: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-right: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  opacity: 1;
}
.svc-toolbox__item:hover .svc-toolbox__item-banner .svc-toolbox__item-title,
.svc-toolbox__item:focus .svc-toolbox__item-banner .svc-toolbox__item-title {
  padding-left: calc(4 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus,
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover {
  overflow: hidden;
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  background-color: var(--sjs-general-backcolor, var(--background, #fff));
  box-shadow: var(--sjs-shadow-medium, 0px 2px 6px 0px rgba(0, 0, 0, 0.1));
  transition: 0.1s ease-in-out;
}
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus .svc-toolbox__item-title,
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .svc-toolbox__item-title {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
}
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):focus .sv-svg-icon use,
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

.svc-toolbox__tool .sv-dots__item:hover:enabled,
.svc-toolbox__tool .sv-dots__item:focus:enabled,
.svc-toolbox__tool .sv-dots__item.sv-action-bar-item--pressed {
  background-color: var(--sjs-primary-backcolor-light, var(--primary-light, rgba(25, 179, 148, 0.1)));
  border-radius: calc(12.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}
.svc-toolbox__tool .sv-dots__item:hover:enabled .sv-svg-icon use,
.svc-toolbox__tool .sv-dots__item:focus:enabled .sv-svg-icon use,
.svc-toolbox__tool .sv-dots__item.sv-action-bar-item--pressed .sv-svg-icon use {
  fill: var(--sjs-primary-backcolor, var(--primary, #19b394));
}

[dir=rtl] .svc-toolbox-popup,
[style*="direction:rtl"] .svc-toolbox-popup,
[style*="direction: rtl"] .svc-toolbox-popup {
  direction: rtl;
}

.svc-toolbox-popup .sv-list__item:first-child .sv-list__item-separator {
  display: none;
}
.svc-toolbox-popup .sv-list__item .sv-list__item-body {
  padding-top: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-bottom: calc(1.5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-start: calc(2 * (var(--sjs-base-unit, var(--base-unit, 8px))));
  padding-inline-end: calc(5 * (var(--sjs-base-unit, var(--base-unit, 8px))));
}

.svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots),
.svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover,
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots),
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover {
  color: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.5;
}
.svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots) .sv-svg-icon use,
.svc-toolbox .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use,
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots) .sv-svg-icon use,
.svc-toolbox:not(.svc-toolbox--compact) .svc-toolbox__tool--pressed .svc-toolbox__item:not(.sv-dots):hover .sv-svg-icon use {
  fill: var(--sjs-general-forecolor, var(--foreground, #161616));
  opacity: 0.5;
}