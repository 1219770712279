@import "../defaultV2-theme/variables.scss";

.sv-list {
  padding: 0;
  margin: 0;
  overflow-y: auto;
  background: $background;
  font-family: $font-family;
  list-style-type: none;
}

.sv-list__empty-container {
  width: 100%;
  font-family: $font-family;
  box-sizing: border-box;
  padding: calcSize(1.5);
}

.sv-list__empty-text {
  line-height: calcLineHeight(1.5);
  font-size: calcFontSize(1);
  font-weight: 400;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: $foreground-light;
}

.sv-list__item {
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  color: $foreground;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.sv-list__item-body {
  position: relative;
  width: 100%;
  align-items: center;
  box-sizing: border-box;
  // padding: calcSize(1) calcSize(8) calcSize(1) calcSize(2);
  padding-block: calcSize(1);
  padding-inline-end: calcSize(8);
  padding-inline-start: calcSize(2);
  color: $font-questiontitle-color;
  font-weight: normal;
  font-size: calcFontSize(1);
  line-height: calcLineHeight(1.5);
  cursor: pointer;
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
  transition:
    background-color $transition-duration,
    color $transition-duration;
}

.sv-list__item.sv-list__item--focused:not(.sv-list__item--selected) {
  outline: none;

  .sv-list__item-body {
    @include borderLight;
    border-radius: calcCornerRadius(1);
    padding-block: calcSize(0.75);
    padding-inline-end: calcSize(7.75);
    padding-inline-start: calcSize(1.75);
  }

  .sv-string-viewer {
    margin-inline-start: calcSize(-0.25);
  }
}

.sv-list__item:hover,
.sv-list__item:focus {
  outline: none;
}

.sv-list__item:focus .sv-list__item-body,
.sv-list__item--hovered > .sv-list__item-body {
  background-color: $background-dark;
}

.sv-list__item--with-icon.sv-list__item--with-icon {
  padding: 0;

  & > .sv-list__item-body {
    padding-top: calcSize(1.5);
    padding-bottom: calcSize(1.5);
    gap: calcSize(2);
    display: flex;
  }
}

.sv-list__item-icon {
  float: left;
  flex-shrink: 0;
  width: calcSize(3);
  height: calcSize(3);

  svg {
    display: block;
  }

  use {
    fill: $foreground-light;
  }
}

.sv-list-item__marker-icon {
  position: absolute;
  right: calcSize(1);
  flex-shrink: 0;
  padding: calcSize(0.5);

  svg {
    display: block;
  }

  use {
    fill: $foreground-light;
  }
}

[dir="rtl"],
[style*="direction:rtl"],
[style*="direction: rtl"] {
  .sv-list__item-icon {
    float: right;
  }
}

.sv-list__item-separator {
  margin: calcSize(1) 0;
  height: 1px;
  background-color: $border;
}

.sv-list--filtering {
  .sv-list__item-separator {
    display: none;
  }
}

.sv-list__item.sv-list__item--selected,
.sv-list__item.sv-list__item--selected:hover,
.sv-list__item.sv-list__item--selected.sv-list__item--focused,
.sv-multi-select-list .sv-list__item.sv-list__item--selected.sv-list__item--focused,
li:focus .sv-list__item.sv-list__item--selected {
  & > .sv-list__item-body {
    background-color: $primary;
    color: $primary-foreground;
    font-weight: 600;
  }

  .sv-list__item-icon use {
    fill: $background;
  }

  .sv-list-item__marker-icon use {
    fill: $primary-foreground;
  }
}

.sv-multi-select-list .sv-list__item.sv-list__item--selected,
.sv-multi-select-list .sv-list__item.sv-list__item--selected:hover {
  .sv-list__item-body {
    background-color: $primary-light;
    color: $font-questiontitle-color;
    font-weight: 400;
  }
}

.sv-list__item.sv-list__item--selected.sv-list__item--group {
  & > .sv-list__item-body {
    background-color: $primary-light;
    color: $font-questiontitle-color;
    font-weight: 400;

    use {
      fill: $foreground-light;
    }
  }
}

.sv-list__item.sv-list__item--disabled {
  .sv-list__item-body {
    cursor: default;
    color: $foreground-light;
  }
}

.sv-list__item span {
  white-space: nowrap;
}

.sv-list__item-text--wrap span {
  white-space: normal;
  word-wrap: break-word;
}

.sv-list__container {
  position: relative;
  display: flex;
  height: 100%;
  flex-direction: column;
  display: flex;
  min-height: 0;
}

.sv-list__filter {
  border-bottom: 1px solid $border-inside;
  background: $background;
  padding-bottom: calcSize(1);
}

.sv-list__filter-icon {
  display: block;
  position: absolute;
  top: calcSize(1.5);
  inset-inline-start: calcSize(2); //left: calcSize(2);

  .sv-svg-icon {
    width: calcSize(3);
    height: calcSize(3);

    use {
      fill: $foreground-light;
    }
  }
}

.sv-list__input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: block;
  background: $background;
  box-sizing: border-box;
  width: 100%;
  min-width: calcSize(30);
  outline: none;
  font-size: calcFontSize(1);
  color: $foreground;
  padding: calcSize(1.5) calcSize(3);
  padding-inline-start: calcSize(7);
  line-height: calcLineHeight(1.5);
  border: none;
}

.sv-list__input::placeholder {
  color: $foreground-light;
}

.sv-list__input:disabled,
.sv-list__input:disabled::placeholder {
  color: $foreground-light;
}

.sv-list__loading-indicator {
  pointer-events: none;
}

.sv-list__loading-indicator .sv-list__item-body {
  background-color: transparent;
}
